import HttpService from '../HttpService'

export default class VaccineEventService {
    constructor() {
        this._httpService = new HttpService(`healthcare/vaccine_event`);
    }

    async getTotalizers(){
        return await this._httpService.get('/get_vaccine_event_totalizers');
    }

    async getVaccinesFindAllByUser(){
        return await this._httpService.get('/find_all_by_user');
    }

    async getLabHistory(){
        return await this._httpService.get('/find_laboratories_history_by_user');
    }

    async createVaccine(data){
        return await this._httpService.post('/', data);
    }

    async realizeVaccine(data){
        return await this._httpService.post('/realize_vaccine', data);
    }

    async updateVaccine(id, data){
        return await this._httpService.put(`/${id}`, data);
    }

}
